<template>
  <div class="p-1">
    <div-table
      :config="configList"
      :idWharhouse="idWharhouse"
      :currentUser="currentUser"
      :data="warehouses"
      @reset="reset"
      @refresh="refresh"
      @filtrer="filtrer"
      @search="search"
      :loading="loading"
      :paginate="paginate"
      @paginatation="paginatation"
    ></div-table>
  </div>
</template>

<script>
import config from "./config";
export default {
  props: {
    currentUser: { type: Object },
    idWharhouse: { type: Object },
  },
  data() {
    return {
      configList: config.list,
      loading: false,
      content: {},
      warehouses: [],
      // currentUser: {},
      limit: 10,
      paginate: {
        total: 0,
        currentpage: 1,
        lastpage: 1,
        per_page: 0,
      },
    };
  },
  async mounted() {
    //await this.getUser();
    this.getWarehouses({ limit: this.limit });
  },

  filters: {},
  methods: {
    async getWarehouses(filters) {
      const res = await this.$server.search("warehouses", filters);
      if (res.content.results) {
        this.warehouses = res.content.results;
        this.warehouses.total = res.content.total;
        this.warehouses.lastpage = res.content.last_page;
        this.warehouses.per_page = res.content.per_page;
        this.warehouses.currentpage = res.content.current_page;
      } else {
        this.warehouses = [];
        this.paginate = { total: 0, currentpage: 1, lastpage: 1, per_page: 0 };
      }
    },

    async refresh() {
      this.getWarehouses({ limit: this.limit });
    },

    /*********************************************************************************/
    async reset(data) {
      const filters = {
        limit: this.limit,
      };
      await this.getWarehouses(filters);
    },
    async search(data) {
      const search = {
        name: data,
      };
      const res = await this.$server.find("warehouses", search);

      if (res.content) {
        this.warehouses = res.content;
        this.warehouses.total = res.content.total;
        this.warehouses.lastpage = res.content.last_page;
        this.warehouses.per_page = res.content.per_page;
        this.warehouses.currentpage = res.content.current_page;
      } else {
        this.warehouses = [];
        this.paginate = { total: 0, currentpage: 1, lastpage: 1, per_page: 0 };
      }
    },
    async filtrer(data) {
      const filters = {
        name: data.name,
        limit: this.limit,
      };
      await this.getWarehouses(filters);
    },
    /****************************************************************************/
    async getUser() {
      const res = await this.$server.me("users");
      if (res.content) this.currentUser = res.content;
      else this.currentUser = [];
    },
    /***************************************************************************************/
    async paginatation(direction) {
      var page = 0;
      if (direction == "next") {
        page = ++this.paginate.currentpage;
      } else {
        page = --this.paginate.currentpage;
      }
      const filters = {
        page: page,
        limit: this.limit,
      };
      this.getWarehouses(filters);
    },
  },
};
</script>

<style></style>
